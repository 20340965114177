/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /* SLIDER LIST */
        $('.sliders-list').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true
        });

        /* IMGS PROGRAM LIST */
        $('.single-program-imgs').slick({
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            fade: true,
            prevArrow: '<div class="single-program-img-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
            nextArrow: '<div class="single-program-img-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
      });


        /* COLUMNS INFO */
        /* COLUMNS INFO - SLICK MOBILE */
        $('.module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-mobile').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true
        });

        /* COLUMNS INFO - POSITION BACKGROUND */
        var bg_position = 0;
        var columns_width = $('.module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .col-columns').outerWidth();
        $( ".module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .column-element" ).each(function( index ) {
            $( this ).css("background-position-x", bg_position);
            bg_position = bg_position - columns_width;
        });

        /* COLUMNS INFO - EQUAL HEIGHT */
        var elementHeights = $('.module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .column-element .column-info').map(function() {
            return $(this).height();
        }).get();
        var maxHeight = Math.max.apply(null, elementHeights);
        $('.module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .column-element .column-info').height(maxHeight);


        /* HIGHLIGHTS INFO */
        /* HIGHLIGHTS INFO - SLICK MOBILE */
        $('.module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-mobile').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true
        });

        /* HIGHLIGHTS INFO - POSITION BACKGROUND */
        var hl_bg_position = 0;
        var hl_columns_width = $('.module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .col-highlights').outerWidth();
        $( ".module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .highlight-element a" ).each(function( index ) {
            $( this ).css("background-position-x", hl_bg_position);
            hl_bg_position = hl_bg_position - hl_columns_width;
        });

        /* HIGHLIGHTS INFO - POSITION BACKGROUND - SECOND ROW */
        var sr_hl_bg_position_x = 0;
        var sr_hl_bg_position_y = 0;
        var sr_hl_columns_width = $('.module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .col-highlights').outerWidth();
        var sr_hl_columns_height = $('.module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .col-highlights').outerHeight();
        $( ".module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .second-row .highlight-element a" ).each(function( index ) {
            $( this ).css("background-position-x", sr_hl_bg_position_x);
            $( this ).css("background-position-y", sr_hl_bg_position_y - sr_hl_columns_height);
            sr_hl_bg_position_x = sr_hl_bg_position_x - sr_hl_columns_width;
        });

        // SEARCH IN NAVIGATION
        $(".nav-primary #searchform .search-button").toggle(function() {
          $("#searchform").toggleClass("open");
        }, function() {
          $("#searchform").toggleClass("open");
        });
        $('.nav-primary #searchform').each(function() {
          $(this).find('input').keypress(function(e) {
              // Enter pressed?
              if(e.which === 10 || e.which === 13) {
                  this.form.submit();
              }
          });
        });

        // CLOSE MODULES LIST PROGRAMS
        $('.program-element-wrapper .module-activities-program').removeClass('open');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        /*
        * Load More
        */
        $('.posts_loadmore_button').click(function(){
          $.ajax({
              url : misha_loadmore_params.ajaxurl,
              data : {
                  'action': 'loadmorebutton',
                  'security': misha_loadmore_params.security,
                  'query': misha_loadmore_params.posts,
                  'page' : misha_loadmore_params.current_page
              },
              type : 'POST',
              beforeSend : function ( xhr ) {
                  $('.posts_loadmore_button').text('Cargando publicaciones...');
              },
              success : function( posts ){
                  if( posts ) {
                      $('.posts_loadmore_button').text('Ver más publicaciones');
                      $('#elements-list').append(posts);
                      misha_loadmore_params.current_page++;
                      if ( misha_loadmore_params.current_page == misha_loadmore_params.max_page ){
                          $('.posts_loadmore').hide();
                      }
                  } else {
                      $('.posts_loadmore').hide(); // if no data, HIDE the button as well
                  }
              }
          });
          return false;
        });


        /*
        * Filter posts
        */
        $('#cat').change(function(){
            if ( $(this).val() > 0 ) {
               location.href = $(this).children(":selected").data("url");
            }
            if ( $(this).val() < 0 ) {
                location.href = $(this).children(":selected").data("url");
            }
        });

        /*
        * Filter activities
        */
        $('#filter-form-activities .filter-select').change(function(){
          var filter = $('#filter-form-activities');
          $.ajax({
              url: filter.attr('action'),
              data: filter.serialize(),
              type : 'POST',
              success:function(data){
                  if( data ) {
                      $('#elements-list').html(data); // insert data
                  }
              }
          });
          return false;
        });

        /*
        * Filter activity city
        */
        $('#filter-form-act-city .filter-select').change(function(){
          var filter = $('#filter-form-act-city');
          $.ajax({
              url: filter.attr('action'),
              data: filter.serialize(),
              type : 'POST',
              success:function(data){
                  if( data ) {
                      $('#elements-list').html(data); // insert data
                  }
              }
          });
          return false;
        });

        /* NEXT EVENTS ACCORDION */
        $('.toggle-accordion').click(function() {
            $(this).parent(".module-ap-accordion").children('.module-ap-element').toggle("fast");
            $(this).parent(".module-ap-accordion").toggleClass("open");
        });


        /* SCROLL */
        $(window).on("scroll touchmove", function () {
            /* HEADER SHRINK */
            var h_header_wrapper = $('.header-wrapper.shrink-enabled').height();
            $('.header-wrapper.shrink-enabled').toggleClass('shrink', $(document).scrollTop() > h_header_wrapper);
        });

        /* RESIZE */
        $(window).on("resize", function () {
            /* RESIZE - COLUMNS INFO - POSITION BACKGROUND */
            var bg_position = 0;
            var columns_width = $('.module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .col-columns').outerWidth();
            $( ".module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .column-element" ).each(function( index ) {
              $( this ).css("background-position-x", bg_position);
                bg_position = bg_position - columns_width;
            });

            /* RESIZE - COLUMNS INFO - EQUAL HEIGHT */
            var elementHeights = $('.module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .column-element .column-info').map(function() {
                return $(this).height();
            }).get();
            var maxHeight = Math.max.apply(null, elementHeights);
            $('.module-columns-wrapper .column-wrapper-bg .col-columns-wrapper.columns-desktop .column-element .column-info').height(maxHeight);

            /* RESIZE - HIGHLIGHTS INFO - POSITION BACKGROUND */
            var hl_bg_position = 0;
            var hl_columns_width = $('.module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .col-highlights').outerWidth();
            $( ".module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .highlight-element a" ).each(function( index ) {
                $( this ).css("background-position-x", hl_bg_position);
                hl_bg_position = hl_bg_position - hl_columns_width;
            });

            /* RESIZE - HIGHLIGHTS INFO - POSITION BACKGROUND - SECOND ROW */
            var sr_hl_bg_position_x = 0;
            var sr_hl_bg_position_y = 0;
            var sr_hl_columns_width = $('.module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .col-highlights').outerWidth();
            var sr_hl_columns_height = $('.module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .col-highlights').outerHeight();
            $( ".module-highlights-wrapper .highlights-wrapper-bg .col-highlights-wrapper.highlights-desktop .second-row .highlight-element a" ).each(function( index ) {
                $( this ).css("background-position-x", sr_hl_bg_position_x);
                $( this ).css("background-position-y", sr_hl_bg_position_y - sr_hl_columns_height);
                sr_hl_bg_position_x = sr_hl_bg_position_x - sr_hl_columns_width;
            });
        });

          /*  MENU - MOBILE */
          $('.hamburger').on('click', function() {
              $(this).fadeOut("fast");
              $('body').addClass("show").animate({"margin-left": '-=300'}, function(){
                  $('.btn-close-nav-mobile').fadeIn("fast");
              });
              $('.nav-mobile').addClass("show");
              $('.nav-mobile').animate({"right": '+=300'});
              $('body').toggleClass('nav-expanded');
          });
          $('.btn-close-nav-mobile').on('click', function() {
              $(this).fadeOut("fast");
              $('body').animate({"margin-left": '+=300'}, function() {
                  $(this).removeClass("show");
                  $('.hamburger').fadeIn("fast");
              });
              $('.nav-mobile').animate({"right": '-=300'});
              $('.nav-mobile').removeClass("show");
              $('body').toggleClass('nav-expanded');
          });
          /* MENU - SUBMENU - MOBILE */
          $(".nav-mobile li > span").on('click', function() {
              var btnMenu = $(this);
              var submenu = $(this).closest("li").find(".sub-menu-mobile");
              submenu.slideToggle("fast");
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
